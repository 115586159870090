<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Pagos - Configuración</h4>
            <div class="small text-muted">Configuración de las formas de pagos disponibles</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer" no-body>
        <b-row>
          <b-col>      
            <b-tabs v-model="tabIndex" card>
              
              <b-tab title="Mercado Pago" :title-link-class="linkClass(0)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      Mercado Pago
                      <small>
                        <b-badge variant="success" v-if="crud.mercado_pago.active">ACTIVO</b-badge>
                        <b-badge variant="danger" v-else>INACTIVO</b-badge>
                      </small>
                    </h5>                      
                  </div>
                  
                  <b-row>
                    <b-col md="6" sm="12">
                      <b-row>
                        <b-col md="6">
                          <b-form-group label="Client ID">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.mercado_pago.app_id">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Client Secret">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.mercado_pago.app_secret">
                            </b-form-input>
                          </b-form-group>
                        </b-col> 
                        
                        <b-col md="8">
                          <b-form-group label="URL base" description="Debe ingresar la url completa su tienda Ej: https://www.dominio.com/tienda">
														<b-form-input type="text"
                                          size="sm"
                                          v-model="crud.mercado_pago.back_url_base">
            								</b-form-input>
                          </b-form-group>
                        </b-col>

                        <b-col md="4">
                          <b-form-group label="Sandbox">
														<b-form-checkbox v-model="crud.mercado_pago.sandbox_mode" 
                              							 switch 
                              							 size="sm">
															Modo Testing
            								</b-form-checkbox>   
                          </b-form-group>
                        </b-col>
                      </b-row>
                      
                      <b-row>
                        <b-col md="12">                          
                          <b-button variant="dark" size="sm" @click="saveMercadoPago()">Guardar</b-button>                        
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="12">
                          <hr>
                        </b-col> 
                        
                        <b-col md="12" class="mb-2">
                          <b-button variant="secondary" size="sm" @click="testMercadoPago()">Probar Conexión</b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6" sm="12">
                      <b-alert show variant="warning">
                        <b>Mercado Pago</b> solo vamos a utilizarlo para transacciones dentro de <b>Argentina</b>
                      </b-alert>
                      <b-card bg-variant="light" header="Implementación" text-variant="dark">                        
                        <b-card-text>
                          1) <b>Crear cuenta de Mercado Pago</b>
                          <br>
                          Para poder comenzar la integración, es necesario contar con una cuenta de Mercado Pago.<br>
                          En el siguiente link se puede <a href="https://www.mercadopago.com.ar/" target="_blank">crear una cuenta</a> sin costo.
                          <br>
                          <hr>
                          2) <b>Obtener Credenciales</b>
                          <br>
                          Ingresa en tus <a href="https://www.mercadopago.com.ar/settings/account/credentials" target="_blank">credenciales</a> y seleccioná la opción "Credenciales de producción". Luego complete la configuración con el <b>Cliente ID</b> y el <b>Cliente Secret</b>
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-tab>

              <b-tab title="Paypal" :title-link-class="linkClass(1)">
                <b-card-text>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-3">
                      Paypal
                      <small>
                        <b-badge variant="success" v-if="crud.paypal.active">ACTIVO</b-badge>
                        <b-badge variant="danger" v-else>INACTIVO</b-badge>
                      </small>                      
                    </h5>                      
                  </div>

                  <b-row>
                    <b-col md="6" sm="12">
                      <b-row>
                        <b-col md="6">
                          <b-form-group label="Client ID">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.paypal.app_id">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Secret">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.paypal.app_secret">
                            </b-form-input>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group label="Client ID (Sandbox)">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.paypal.sandbox_app_id">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Secret (Sandbox)">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.paypal.sandbox_app_secret">
                            </b-form-input>
                          </b-form-group>
                        </b-col>  
                        
                        <b-col md="8">
                          <b-form-group label="URL base" description="Debe ingresar la url completa su tienda Ej: https://www.dominio.com/tienda">
														<b-form-input type="text"
                                          size="sm"
                                          v-model="crud.paypal.back_url_base">
            								</b-form-input>
                          </b-form-group>
                        </b-col>

                        <b-col md="4">
                          <b-form-group label="Sandbox">
														<b-form-checkbox v-model="crud.paypal.sandbox_mode" 
                              							 switch 
                              							 size="sm">
															Modo Testing
            								</b-form-checkbox>   
                          </b-form-group>
                        </b-col>
                      </b-row>
                      
                      <b-row>
                        <b-col md="12">                          
                          <b-button variant="dark" size="sm" @click="savePaypal()">Guardar</b-button>                        
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="12">
                          <hr>
                        </b-col> 
                        
                        <b-col md="12" class="mb-2">
                          <b-button variant="secondary" size="sm" @click="testPaypal()">Probar Conexión</b-button>
                        </b-col>
                      </b-row>                      
                    </b-col>
                    <b-col md="6" sm="12">
                      <b-alert show variant="warning">
                        <b>Paypal</b> vamos a utilizarlo para transacciones del <b>Resto del Mundo</b> excepto <b>Argentina</b>
                      </b-alert>

                      <b-card bg-variant="light" header="Implementación" text-variant="dark">
                        <b-card-text>                          
                          1) <b>Abrir cuenta</b>
                             <br>
                            Si aún no creaste una cuenta, podés hacerlo desde <a href="https://www.paypal.com/es/webapps/mpp/account-selection" target="_blank">Paypal</a>. 
                            <br>
                            <i>Te recomendamos abrir una "Cuenta de Negocios" porque posee más servicios y opciones para el vendedor que te pueden ayudar en tu gestión de cobranzas.</i>
                          <br>
                          <hr>
                          2) <b>Crear APP</b> 
                             <br>
                             Ingrese al siguiente enlace <a href="https://www.paypal.com/signin?returnUri=https%3A%2F%2Fdeveloper.paypal.com%2Fdeveloper%2Fapplications" target="_blank">Paypal Developer</a>
                             <br>                            
                             Una vez que haya iniciado sesión, vaya al menú lateral y seleccione la opción “My Apps & Credentials”.
                             Luego seleccione el botón de "Live" y después presione el botón “Create App”.
                             Por último ingrese el nombre de la aplicación y proceder a presionar el botón “Create App”
                             <br>
                             <br>
                             Si necesita crear un entorno de prueba para validar el funcionamiento, en vez de crear una APP modo "Live",
                             debe dejar el modo "Sandbox".
                          <br>
                          <hr>
                          3) <b>Obtener Client ID y Secret</b>
                              <br>
                              Deberías visualizar el "Cliente ID" y el "Secret" (haciendo click en el botón "show").
                              Copielos y complete la configuración de nuestro sistema.                              
                        </b-card-text>
                      </b-card>
                    </b-col>                  
                  </b-row>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.PAGOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'configuration',
          elements: {}
        }, 
        crud: {
          mercado_pago: {
            id: 1,
            app_id: '',
            app_secret: '',
            sandbox_mode: true,
            back_url_base: '',
            active: false,
          },   
          paypal: {
            id: 1,            
            app_id: '',
            app_secret: '',
            sandbox_app_id: '',
            sandbox_app_secret: '',
            sandbox_mode: true,
            back_url_base: '',
            active: false,
          }
        },
        tabIndex: 0     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted () {
      this.showMercadoPago()
      this.showPaypal()
    },
    methods: {
      linkClass(idx) {
        if (this.tabIndex === idx) {
          return ['bg-primary', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      }, 

      showMercadoPago() {        
        var result = serviceAPI.showMercadoPago(this.crud.mercado_pago)

        result.then((response) => {
          var data = response.data
          
          this.crud.mercado_pago.app_id = data.app_id
          this.crud.mercado_pago.app_secret = data.app_secret
          this.crud.mercado_pago.sandbox_mode = data.sandbox_mode
          this.crud.mercado_pago.back_url_base = data.back_url_base
          this.crud.mercado_pago.active = data.active
        })
      },
      saveMercadoPago() {
        this.$bvModal.msgBoxConfirm('¿Guardar datos de mercado pago?', {
          title: 'Guardar configuración de mercado pago',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.saveMercadoPago(this.crud.mercado_pago);

            result.then((response) => {          
              loader.hide()          
              this.showMercadoPago()
              this.$awn.success("Datos de mercado pago guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      testMercadoPago() {
        let loader = this.$loading.show();
        var result = serviceAPI.testMercadoPago(this.crud.mercado_pago);

        result.then((response) => {          
          loader.hide()          
          this.showMercadoPago()
          this.$awn.success("El servicio de Mercado Pago fue activado");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        }) 
      },      

      showPaypal() {        
        var result = serviceAPI.showPaypal(this.crud.paypal)

        result.then((response) => {
          var data = response.data
          
          this.crud.paypal.app_id = data.app_id
          this.crud.paypal.app_secret = data.app_secret  
          this.crud.paypal.sandbox_app_id = data.sandbox_app_id
          this.crud.paypal.sandbox_app_secret = data.sandbox_app_secret  
          this.crud.paypal.sandbox_mode = data.sandbox_mode          
          this.crud.paypal.back_url_base = data.back_url_base
          this.crud.paypal.active = data.active
        })
      },
      savePaypal() {
        this.$bvModal.msgBoxConfirm('¿Guardar datos de paypal?', {
          title: 'Guardar configuración de paypal',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GUARDAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: "dark",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.savePaypal(this.crud.paypal);

            result.then((response) => {          
              loader.hide() 
              this.showPaypal()         
              this.$awn.success("Datos de paypal guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            }) 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      testPaypal() {
        let loader = this.$loading.show();
        var result = serviceAPI.testPaypal(this.crud.paypal);

        result.then((response) => {          
          loader.hide()          
          this.showPaypal()
          this.$awn.success("El servicio de Paypal fue activado");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        }) 
      },   
    } 
  }
</script>